import React, { Component, Fragment } from 'react'
import { graphql } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import Img from 'gatsby-image'

import SEO from '../components/Seo'
import Post from '../components/Post/Post'
import { updateImage } from '../utils/updateImage'

class BlogPage extends Component {
  state = {
    isMobile: false,
    counter: 1
  }

  checkDevice = () => {
    this.setState({ isMobile: window.innerWidth <= 767 })
  }

  loadHandler = () => {
    this.setState({ counter: this.state.counter + 1 })
  }

  componentDidMount () {
    setTimeout(this.checkDevice, 0)
    window.addEventListener('resize', this.checkDevice)
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.checkDevice)
  }

  render () {
    const {
      recentArticles: { edges: articlesArray },
      header: {
        frontmatter: {
          header,
          slogan
        }
      },
      seo: {
        frontmatter: {
          seo: seoInfo
        }
      },
      intrestingSectionLabel: {
        frontmatter: {
          label: interestingSectionLabel
        }
      },
      recentSectionLabel: {
        frontmatter: {
          label: recentSectionLabel
        }
      },
      promotedArticles: { edges: promotedArticles },
      topArticle: { frontmatter: topArticle }
    } = this.props.data

    let recentArticles = []
    if (articlesArray.length !== 0) {
      recentArticles = this.state.isMobile ? articlesArray.slice(0, this.state.counter * 3)
        : articlesArray.slice(0, this.state.counter * 6)
      const iteratorArray = Array.from({
        length:
          recentArticles.length < 3 ? 1 : Math.ceil(recentArticles.length / 3)
      })
      recentArticles = iteratorArray.map((_, i) => recentArticles.slice(i * 3, i * 3 + 3))
    }
    const { data } = this.props
    return (
      <Fragment>
        <SEO data={seoInfo} />
        <section className="blog-page wrapper">
          <h1 className="blog-page-header">
            {header}
          </h1>
          <div className="blog-page-header-description">
            {slogan}
          </div>
          <article className="top-article-wrapper">
            <a
              className="top-post-link"
              href={`/blog/${ data.topArticle.fields.slug }/`}
            >
              <div className="top-article-image">
                <Img
                  // removed largest image size from srcSet, if it exist
                  fluid={{
                    ...updateImage(topArticle.image.childImageSharp.fluid),
                    sizes: `(max-width: 440px) 375px,
                    (max-width: 992px) 736px, 
                    (max-width: 1200px) 994px, 1247px`
                  }}
                  loading="eager"
                  alt={topArticle.title}
                />
              </div>

              <div className="top-article-context-wrapper">
                <div className="top-article-header">
                  {topArticle.title}
                </div>
                <div className="post-author-wrapper">
                  <div className="author-image white">
                    <img
                      src={topArticle.authorImage.childImageSharp.fixed.base64}
                      alt={topArticle.author}
                    />
                  </div>
                  <div className="author-name-wrapper">
                    <div className="author-name white">
                      {topArticle.author}
                    </div>
                    <div className="post-date white">
                      {topArticle.date}
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </article>
          <div className="promoted-articles-wrapper">
            <div className="blog-page-sub-header"
              dangerouslySetInnerHTML={{ __html: interestingSectionLabel }}
            />
            <div className="promoted-articles-container">
              {promotedArticles.map(({ node }) => (
                <Post
                  key={node.id}
                  title={node.frontmatter.title}
                  slug={`/blog/${ node.fields.slug }/`}
                  image={node.frontmatter.image.childImageSharp.fluid}
                  date={node.frontmatter.date}
                  authorName = {node.frontmatter.author}
                  authorImage = {node.frontmatter.authorImage.childImageSharp.fixed.base64}
                  articleDescription = {node.excerpt}
                />
              ))}
            </div>
          </div>

          <div className="recent-articles-wrapper">
            <div className="blog-page-sub-header"
              dangerouslySetInnerHTML={{ __html: recentSectionLabel }}
            />
            {recentArticles.length && (
              <div className="recent-articles-container">
                {recentArticles.map((articlesBlock, i) => (
                  <div
                    className="block-wrapper"
                    key={i}
                  >
                    {articlesBlock.map(({ node }) => (
                      <article
                        className="recent-post"
                        key={node.id}
                      >
                        <a
                          className="post-link"
                          href={`/blog/${ node.fields.slug }/`}
                        >
                          <BackgroundImage
                            className="post-background-image"
                            // removed largest image size from srcSet, if it exist
                            fluid={{
                              ...updateImage(node.frontmatter.image.childImageSharp.fluid),
                              sizes: `(max-width: 440px) 360px, 500px`
                            }}
                          />
                          <div className="post-header">
                            {node.frontmatter.title}
                          </div>
                          <div className="post-description-wrapper">
                            <div className="post-author-wrapper">
                              <div className="author-image">
                                <img
                                  src={node.frontmatter.authorImage.childImageSharp.fixed.base64}
                                  alt={node.frontmatter.author}
                                />
                              </div>
                              <div className="author-name-wrapper">
                                <div className="author-name">
                                  {node.frontmatter.author}
                                </div>
                                <div className="post-date">
                                  {node.frontmatter.date}
                                </div>
                              </div>
                            </div>
                            <div className="post-text">
                              {node.excerpt}
                            </div>
                          </div>
                        </a>
                      </article>
                    )
                    )}
                  </div>
                )
                )}
              </div>
            )}
            {(recentArticles.length && recentArticles.length * 3 < articlesArray.length) && (
              <div
                className="load-more"
                onClick={this.loadHandler}
              >
                Load more
              </div>
            )}
          </div>
        </section>
      </Fragment>
    )
  }
}

export const articlesQuery = graphql`
  query ($topArticle: String!, $promotedArticles: [String]!) {
    recentArticles: allMarkdownRemark (
      limit: 1000
      sort: {
        fields: [frontmatter___date]
        order: DESC
      }
      filter: {
        fileAbsolutePath: {regex: "/content/individual-pages/articles/"}
        fields: {slug: {nin: $promotedArticles, ne: $topArticle}}
      }
    ) {
      edges {
        node {
          id
          excerpt(pruneLength: 350)
          frontmatter {
            title
            date(formatString: "DD MMM YYYY")
            author
            authorImage {
              childImageSharp {
                fixed (base64Width: 100){
                  base64
                }
              }
            }
            image {
              childImageSharp {
                fluid(
                  maxWidth: 500,
                  maxHeight: 270,
                  quality: 85, 
                  cropFocus: CENTER,
                  srcSetBreakpoints: [360, 500]
                ) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          fields {
            slug
          }
        }
      }
    }
    seo: markdownRemark(
      fileAbsolutePath: {regex: "/content/general-pages/blog/seo-blog.md/"}
    ) {
      frontmatter {
        seo {
          canonical
          title
          description
          keywords
          robots
          image {
            childImageSharp {
              original {
                src
              }
            }
          }
          imageAlt
          twitterCard
        }
      }
    }
    header: markdownRemark(
      fileAbsolutePath: {regex: "/content/general-pages/blog/header.md/"}
    ) {
      frontmatter {
        header
        slogan
      }
    }
    topArticle: markdownRemark(fields: { slug: { eq: $topArticle }}) {
      frontmatter {
        title
        date(formatString: "DD MMM YYYY")
        author
        authorImage {
          childImageSharp {
            fixed (base64Width: 100){
              base64
            }
          }
        }
        image {
          childImageSharp {
            fluid(
              maxWidth: 1274, 
              maxHeight: 706, 
              quality: 90, 
              cropFocus: CENTER,
              srcSetBreakpoints: [375, 736, 944, 1274]
            ) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      fields {
        slug
      }
    }
    intrestingSectionLabel: markdownRemark(
      fileAbsolutePath: {regex: "/content/general-pages/blog/interesting-for-you-articles.md/"}
    ) {
      frontmatter {
        label
      }
    }
    recentSectionLabel: markdownRemark(
      fileAbsolutePath: {regex: "/content/general-pages/blog/recent-articles.md/"}
    ) {
      frontmatter {
        label
      }
    }
    promotedArticles: allMarkdownRemark(
      limit: 3
      sort: {
        fields: [frontmatter___date]
        order: DESC
      }
      filter: {
        fileAbsolutePath: {regex: "/content/individual-pages/articles/"}, 
        fields: {slug: {in: $promotedArticles}}}
    ) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            title
            date(formatString: "DD MMM YYYY")
            author
            authorImage {
              childImageSharp {
                fixed (base64Width: 100){
                  base64
                }
              }
            }
            image {
              childImageSharp {
                fluid(
                  maxWidth: 394, 
                  maxHeight: 237, 
                  quality: 80, 
                  cropFocus: CENTER,
                  srcSetBreakpoints: [280, 394]
                ) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          fields {
            slug
          }
        }
      }
    }
  }
`

export default BlogPage
